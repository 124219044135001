.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 99999;


  @keyframes slideUp {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slideDown {
    from {
      transform: translateY(0);
      opacity: 1;
    }

    to {
      transform: translateY(100%);
      opacity: 0;
    }
  }

  .popup-enter {
    animation: slideUp 0.3s ease-out forwards;
  }

  .popup-exit {
    animation: slideDown 0.3s ease-in forwards;
  }

  .popup {
    background: var(--text-button);
    width: 100%;
    border-radius: 6px 6px 0 0;
    position: fixed;
    bottom: 0;

    .header {
      padding: 9px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid var(--background2);

      .headerLeft {
        width: 10px;
      }

      .headerTitle {
        color: var(--text-title);
        font-size: 14px;
        font-weight: 700;
      }

      .headerRight {
        .headerRightIcon {
          font-size: 24px;
          color: var(--text-disable);
        }
      }
    }

    .content {
      // height: 20px;
      // background: red;
    }

    .footer {
      flex: 1;
      display: flex;
      font-size: 14px;
      line-height: 16px;

      .footerReset {
        flex: 1;
        border-top: 1px solid var(--background2);
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--text-title);
      }

      .footerConfirm {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--gradient-color);
        padding: 8px 0;
        color: var(--text-button);
      }
    }
  }

}
