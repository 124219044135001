.homeMobile {
  position: relative;
  min-height: 80vh;

  .skip {
    position: absolute;
    top: -65px;
    left: 16px;
    border-radius: 24px;
    background: rgba(34, 34, 34, 0.6);
    z-index: 9999;
    border: 1px solid rgba(255, 255, 255, 0.44);
  }

  .content {
    margin: 0 16px;
    margin-bottom: 40px;

    .seeMore {
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--primary);
      font-size: 12px;
      margin-top: 36px;
    }
  }

  .floatButton {}

  .ant-float-btn-body .ant-float-btn-default {
    background-color: transparent !important;
  }

  .mailIcon {
    position: fixed;
    bottom: 100px;
    right: 26px;
    width: 45px;
    height: 45px;
    color: var(--primary);
    background: #fff;
    border-radius: 10px;
  }

  .docuIcon {
    position: fixed;
    bottom: 150px;
    right: 26px;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    color: var(--primary);
    background: #fff;
  }

}

.textButton {
  background: var(--gradient-color);
}

.homeIcon {
  color: var(--primary);
}

.tourCustomList {
  list-style-type: none;
  /* 隐藏默认小圆点 */
  padding-left: 1em;
  /* 控制整体缩进 */
  margin-bottom: 0;
}

.tourCustomList li {
  position: relative;
  padding-left: 8px;
  /* 文本与小圆点的间距 */
  text-indent: -1px;
  /* 反向缩进实现对齐 */
}

.tourCustomList li::before {
  content: "•";
  /* 自定义小圆点 */
  position: absolute;
  left: -5px;
  font-size: 12px;
}
