.tourModal {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 43px;


  .line {
    position: absolute;
    right: -2px;
    top: -66px;
  }

  .desc {
    width: 253px;
    font-size: 12px;
    border-radius: 5px;
    background: var(--text-button);
    padding: 4px 5px;
  }


  .footer {
    margin-top: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
