.emptyMobile {
  display: flex;
  flex-direction: column;
  align-items: center;

  .emptyImg {
    width: 152px;
    margin-top: 30%;
  }

  .emptyMsg {
    margin-top: 8px;
    max-width: 230px;
    text-align: center;
    color: var(--text-title);
    font-size: 14px;
  }

  .emptyBtn {
    display: flex;
    background: var(--gradient-color);
    padding: 4px 22px;
    border-radius: 8px;
    margin-top: 8px;

    .emptyBtnIcon {
      color: var(--text-button);
      font-size: 16px;
      margin-right: 10px;
    }

    .emptyBtnText {
      color: var(--text-button);
      font-size: 14px;
      font-weight: 700;
    }
  }
}
