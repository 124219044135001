.sortInfo {
  padding: 16px 16px 0 16px;

  .sortBox {
    margin-bottom: 16px;
    font-weight: 400;
    line-height: 14px;
    font-size: 12px;
    color: var(--text-title);
  }

  .active {
    color: var(--primary);
    font-weight: 700;
  }
}
