.listCard {
  width: 100%;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
  padding: 8px;
  margin-bottom: 12px;


  .topWrap {
    display: flex;
  }

  .selectBox {
    display: flex;
    align-items: center;

    .unSelect {
      font-size: 20px;
      margin-right: 11px;
      color: var(--text-disable);
    }

    .select {
      font-size: 22px;
      margin-right: 9px;
      color: var(--primary);
    }
  }

  .imageWarp {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .imageBox {
    width: 88px;
    /* 固定宽度 */
    height: 88px;
    /* 固定高度 */
    border-radius: 9px;
    /* 圆角 */
    overflow: hidden;
    /* 超出部分隐藏 */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .imageBoxCom {
    width: 72px;
    height: 72px;
  }

  .image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: auto;

    /* 根据最小边缩放 */
    min-width: 88px;
    /* 最小宽度缩放到 88px */
    min-height: 88px;
    /* 最小高度缩放到 88px */

  }



  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    padding: 0px 0px 4px 8px;
    flex: 1;
  }

  .contentCom {
    padding-bottom: 0;
  }

  .contentTitle {
    font-weight: 700;
    line-height: 14px;
    max-height: 28px;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 这里是超出几行省略 */
    overflow: hidden;
  }

  .contentEarn,
  .contentCommission {
    display: flex;
    align-items: center;
  }

  .earnTitle {
    font-size: 14px;
    color: var(--primary);
    font-weight: 700;
    line-height: 14px;
    margin-right: 8px;
  }

  .comTitle {
    font-size: 14px;
    color: var(--text-disable);
    font-weight: 700;
    line-height: 14px;
    margin-right: 8px;
  }

  .comText {
    color: var(--primary);
    font-size: 12px;
    line-height: 14px;
  }

  .earnText {
    color: var(--text-disable);
    font-size: 12px;
    line-height: 14px;
  }

  .comAdd {
    background: var(--gradient-color);
    border-radius: 9px;
    padding: 5px 12px;
    color: var(--text-button);
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    display: flex;
    justify-content: center;
  }

  .status {
    background: var(--background-status1);
    border-radius: 4px;
    color: var(--text-status1);
    font-size: 12px;
    padding: 3px 1px;
    margin-right: 7px;
    line-height: 14px;
  }

  .statusImg {
    margin: 12px 0 4px 0;
    padding: 3px 5px;
  }

  .statusBad {
    background: var(--background-status2);
    color: var(--text-status2);
  }

  .group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;

    .open {
      font-size: 16px;
      color: var(--text-title);
      margin-right: 4px;
    }

    .collect {
      font-size: 24px;
      color: var(--background1);
    }

    .collectActive {
      color: var(--primary);
    }

    .add {
      background: var(--gradient-color);
      padding: 5px 13px;
      border-radius: 9px;

      .text {
        color: var(--text-button);
        font-size: 12px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .view {
      border: 1px solid var(--primary);
      border-radius: 9px;
      font-size: 12px;
      font-weight: 700;
      color: var(--primary);
      line-height: 16px;
      padding: 3px 8px;
    }

    .viewDiff {
      border: 1px solid var(--background1);
      color: var(--background1);
    }
  }

  .info {
    .line {
      height: 1px;
      background: var(--background3);
      margin: 12px 0 2px 0;
    }

    .infoGroup {
      display: flex;
      justify-content: space-between;
      margin-top: 11px;

      .infoTitle {
        font-size: 12px;
        color: var(--text-disable);
        line-height: normal;
      }

      .infoText {
        color: var(--text-title);
        font-size: 14px;
        line-height: normal;
      }
    }

  }


}

.listCardBorder {
  border: 1px solid var(--primary2);
}
