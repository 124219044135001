.filterMobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 16px 16px;

  .recommend {
    padding: 8px 62px;
    border-radius: 8px;
    border: 1px solid var(--primary);
    display: flex;

    .recommendIcon {
      width: 16px;
      margin-right: 4px;
    }

    .recommendText {
      color: var(--primary);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
    }
  }

  .filterIcon {
    color: var(--text-secondary);
    font-size: 24px;
  }

  .selected {
    color: var(--primary);
  }

  :global {
    .ant-drawer-body {
      margin-top: 116px;
    }
  }


}
