.searchBox {
  padding: 16px;
  padding-top: 0px;

  .infoSearch {
    border-radius: 20px;
    border: 1px solid var(--primary2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;

    .infoSearchLeft {
      color: var(--text-title);
      font-size: 12px;
      display: flex;
      // flex: 1;

      .searchType {
        font-weight: 700;
        padding: 0 12px;
        border-right: 1px solid var(--background2);
      }

      .searchTitle {
        // flex: 1;
        max-width: 180px;
        padding: 0 12px;
        line-height: 14px;
        overflow: hidden;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

    }

    .searchBotton {
      background: var(--gradient-color);
      width: 29px;
      height: 29px;
      border-radius: 20px;
      margin-right: 2px;
      display: flex;
      justify-content: center;
      align-items: center;

      .searchBottonIcon {
        color: var(--text-button);
      }
    }
  }

  .noSearch {
    border-radius: 20px;
    background: var(--background3);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;


    .searchIcon {
      font-size: 16px;
      color: var(--text-disable);
      margin-right: 4px;
    }

    .searchText {
      font-size: 12px;
      line-height: normal;
      color: var(--text-disable);
    }
  }
}
